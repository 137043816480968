import LoaderWidget from "@/components/loader";

export default function Loader(props: { classname?: string }) {
  return (
    <div className={`flex justify-center items-center h-full ${props.classname}`}>
      <div>
        <LoaderWidget />
      </div>
    </div>
  );
}
