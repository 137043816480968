"use client";

import "@/app/firebase";
import { useRouter } from "next/navigation";
import { useEffect } from "react";
import Loader from "@/app/utils/loading";
import useAuth from "@/app/dashboard/hooks/useAuth";
export default function Home() {
  const { user, loading } = useAuth();
  const router = useRouter();

  useEffect(() => {
    if (!loading && !user) {
      return router.push("/sign-in"); // Redirect to login if not authenticated
    } else {
      return router.push("/dashboard");
    }
  }, [user, loading, router]);

  if (loading) {
    return <Loader />;
  }
}
